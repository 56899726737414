@font-face {
  font-family: 'GTWalsheimPro-Black';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-Black.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-Bold';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-CondensedBold';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-CondensedBold.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-CondensedLight';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-CondensedLight.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-CondensedMedium';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-CondensedMedium.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-CondensedRegular';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-CondensedRegular.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-Light';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-Light.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-Medium';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-Medium.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-Regular';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'GTWalsheimPro-UltraLight';
  src: url('./fonts/GTWalsheimPro/GTWalsheimPro-UltraLight.ttf') format('truetype')
}
:root{
  --red: #E74C3C;
  --light-gray: #34495E;
  --gray:#2C3E50;
  --dark-gray:#1A2530;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(44,62,80);
  background: linear-gradient(180deg, rgba(44,62,80,1) 27%, rgba(52,73,94,1) 35%, rgba(52,73,94,1) 99%);
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  outline: none;
  border: none;
  --webkit-user-select: none;
  --moz-user-select: none;
  --ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 4;
}

.background {
  position: fixed;
  top: 0px;
  left:0px;
  bottom: 0px;
  width: 170px;
  background: var(--dark-gray);
  z-index: 4;
  overflow: hidden;
}

 nav,ul,li {
  margin: 0;
  padding: 0;
  z-index: 4;
}

.menu-Ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px;
  position: fixed;
  top: 100px;
  overflow-y: hidden;
  width: 145px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.title-section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden;
}
.navbar{
  width: 150px;
  top: 30px;
  right: 30px;
  position: absolute;
}
.icons{
  transition: color 0.2s;
}
.icons:hover{
  color: var(--red);
}
.nav-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.nav-container a{
  color: white;
}
.GTW{
  font-family: 'GTWalsheimPro-Bold', sans-serif;
}
.title-section h1, h3{
  text-align: left;
  margin-left: 5vw;
}
.title-section h1{
  font-size: 5vmax;
  border-bottom: solid 5px var(--red);
  font-weight: 400;
}
.title-section h3{
font-family: 'GTWalsheimPro-CondensedLight';
}
.title-container{
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.title-container img {
  width: 35vw;
}
.contact-section{
  margin-bottom: 10vh;
}

.portfolio-section h2,
.detail-section h2,
.contact-section h2,
.socials-section h2,
.skills-section h2{
  font-family: 'GTWalsheimPro-Bold';
  color: white;
  margin-left: 20%;
  margin-top: 200px;
  font-size: clamp(3rem,3rem,5rem);

  border-bottom: 2px solid var(--red);
}
.submit-button{
  position: relative;
  width: -webkit-fill-available;
  background-color: var(--light-gray);
  border-radius: 12px;
  color: white;
  font-family: 'GTWalsheimPro-Medium';
  font-size: 1.2rem;
  left:0;
  margin-bottom: 8%;
  transition: 0.2s ease-in-out;
  z-index: 1;
}
.submit-button:hover{
background-color: var(--red);
}
.detail-section h2,.socials-section h2, .contact-section h2{
  margin-top: 50px;
}
.portfolio-section h3{
  color: white;
  margin-left: 24%;
  font-size: clamp(1.3rem, 1.5rem,2rem);
  font-family: 'GTWalsheimPro-Regular';
}
.CardPortfolio{
  background-color: var(--light-gray);
  height: 100%;
  width: 100%;
  right: 0px;
  top: 700px;
  border-top-left-radius: 90%;
  position: absolute;
  z-index: -2;
}
.SpacePortfolio{
  background-color: var(--light-gray);
  height: 80rem;
  width: 100%;
  right: 0px;
  position: absolute;
  z-index: -2;
}
.App-link {
  color: #61dafb;
}
aside{
  width: 13vw;
  min-height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-gray);
  position: fixed;
  z-index: 100;
}
.about-info{
  display: flex;
  flex-direction: column;
}
.sideMenu{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;
}
.sideMenu li {
  list-style-type: none;
}
.sideMenu span {
  background-color: var(--dark-gray);
  display: flex;
  padding: 12px;
  text-decoration: none;
  font-family: 'GTWalsheimPro-Medium';
  font-size: 1.2vmax 1vmin;
  
  color: white;
  justify-content: center;
  transition: background-color 0.2s;
  cursor: pointer;
}
.menuSpan span {
  background-color: var(--dark-gray);
  display: flex;
  padding: 12px;
  text-decoration: none;
  font-family: 'GTWalsheimPro-Medium';
  font-size: 1.2vmax 1vmin;
  color: white;
  justify-content: right;
  transition: background-color 0.2s;
  cursor: pointer;
}

.big-circle{
  background-color: var(--light-gray);
  height: 20vw;
  width: 20vw;
  border-radius: 30%;
  left: 50%;
  margin-top: 5%;
  z-index: -1;
  position: absolute;
}
.big-circle-2{
  background-color: rgb(147, 133, 133);
  height: 18vw;
  width: 18vw;
  border-radius: 30%;
  margin-top: 5%;
  margin-bottom: 7%;
  left: 63%;
  z-index: -3;
  position: absolute;
}
.sideMenu span:hover, .menuSpan span:hover{
  background-color: var(--light-gray);
}
.active span{
  background-color: var(--light-gray);
  border-right: var(--red) 5px solid;
}
.skills-container{
  display: grid;
  gap: 7rem;
  grid-auto-rows: 18rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem),1fr));
  margin-bottom: 15vh;
  margin-left: 15vw;
}
.projects{
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 25px;
  margin: auto;
  color: white;
  margin: -3%;
  z-index: 0;
}
.proyect,.projects{
  list-style: none;
}
.card-title{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'GTWalsheimPro-Medium';
  font-size: 1.2rem;
}
.proyect{
  background-color: var(--light-gray);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}
.project-details {
  width: 100%;
  height: 8px;
  background-color: red;
  border-radius: 10px;
  margin-top: 12px;
}
.proyect:last-child {
  margin-bottom: 0px;
}
.proyect-container{
  display: grid;
  gap: 5rem;
  grid-auto-rows: 10rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%,15rem),1fr));
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 5%;
  margin-bottom: 15%;
}
.skills-section{
  min-height: 40vh;
}
.socials-section{
  min-height: 90vh;
}
.detail-section,.contact-section,.socials-section{
  background-color: var(--light-gray);
  }
  .about-container,.contact-container{
    background-color: var(--gray);
    border-radius: 12px;
    padding: 3%;
    margin-right: 10%;
    margin-top: 5%;
    margin-left: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5%;
    justify-content: space-around;

  }
  .contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: white;
    font-family: 'GTWalsheimPro-Medium';
    font-size: 1.2rem;
  }
  .input-label{
    padding: 4% 0
  }
  .input-field{
    width: -webkit-fill-available;
    -webkit-appearance: none;
    font-family: 'GTWalsheimPro-Light';
    font-size: 1.2rem;
    padding: 0.8rem;
    border-radius: 10px;
    border: none;
    background-color: var(--light-gray);
    color: white;
    font-size: 1.2rem;
  }
  .input-textarea{
    width: -webkit-fill-available;
    -webkit-appearance: none;
    font-family: 'GTWalsheimPro-Light';
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    background-color: var(--light-gray);
    color: white;
    font-size: 1rem; 
  }

  .aboutText{
    margin-right: 5%;
    font-size: clamp(1rem,5vw,1.5rem);
    font-family: 'GTWalsheimPro-Light';
    color: white;
  }
  .card-text{
    text-align: center;
    padding: 3% 0 0 0;
    font-family: 'GTWalsheimPro-Medium';
    font-size: 1.2rem;
  }
  @media screen and (max-width: 1300px) {
    .projects{
      margin: auto;
    }
    .aboutContainer{
      margin: 0 5%;
    }
    .title-section {
      margin-left: -5%;
    }
    .skills-container{
    margin-left: 0vw;
    }
    .proyect-container{
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    }


    @media screen and (max-width: 800px) {
      .about-container{
        flex-direction: column;
      }
    }
  }
@media screen and (max-width: 480px) {
  html {
    overflow-x: hidden;
  }
 
  body {
    overflow-x: hidden;
    position: relative;
    background: linear-gradient(to top, no-repeat,180deg, rgba(44,62,80,1) 26%, rgba(52,73,94,1) 66%, rgba(26,37,48,1) 99%);
  }
  .title-container{
    flex-direction: column;
    margin-top: 3rem;
  }
  .row{
    flex-direction: column;
  }
  .title-section h1{
    font-size: 6.5vmax;
  }
  .title-section h1, h3{
    margin-left: 0px;
    margin-right: 6rem;
  }
  .title-container img {
    width: 85%;
    margin-left: 10%;
  }
  .big-circle{
    height: 25vh;
    width: 25vh;
    border-radius: 30%;
    left: 8%;
    margin-top: 50%;
  }
  .big-circle-2{
    height: 20vh;
    width: 20vh;
    border-radius: 30%;
    margin-top: 0%;
    top: 12%;
    margin-bottom: 0%;
    left: 45%;
  }
  .input-field{
    width: max-content;
  }
  .input-textarea{
    width: 70%;
  }
  .about-container,.contact-container{
    padding: 3%;
    padding-bottom: 7%;
    margin: 5% 8% 5% 8%;
  }
  .CardPortfolio{
    top: 60vh;
    height: 20%;
    width: 70%;
    border-bottom-left-radius: 200%;
    border-bottom-right-radius: 50%;
    transform: rotate(90deg);
}
.portfolio-section{
  overflow: hidden;
  background: rgb(52,73,94);
  background: linear-gradient(0deg, rgba(52,73,94,1) 56%, rgba(52,73,94,0.9192051820728291) 80%, rgba(52,73,94,0) 99%);
}

.portfolio-section h2,.detail-section h2,.socials-section h2, .contact-section h2{
  margin-left: 15%;
  margin-top: 150px;
}

.portfolio-section h3{
  text-align: start;
  margin-left: 10%;
  margin-right: 10%;
}
}